<template>
  <div>
    <div class="inner job-posting pb0">
      <div class="tit req-type">
        <h1>회원 탈퇴</h1>
        <p class="stit">회원 탈퇴를 신청할 수 있습니다</p>
      </div>
      <div v-if="isAuthorized" class="sub-cont">
        <div class="myp-form-wrap">
          <dl>
            <dt>회원탈퇴 신청 완료</dt>
            <dd>
              <div class="withdrawal-item-wrap">
                <img src="~assets/image/ico/ico-withdrawal-complete.png" />
                <div class="wtit">회원 탈퇴 신청이 접수되었습니다.</div>
                <div class="wdesc">잠시 후 메인페이지로 이동합니다.</div>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <secure-login
        ref="secLoginForm"
        :isSns="isNaver || isKakao"
        v-else
        v-show="!isAuthorized"
      ></secure-login>
    </div>
    <div class="myp-btn-wrap">
      <button
        v-if="isAuthorized"
        type="button"
        class="btn-withdrawal"
        @click="rollbackRelease"
      >
        회원 탈퇴 신청 취소
      </button>
      <button v-else type="button" class="btn-save01" @click="secureLogin">
        회원 탈퇴
      </button>
    </div>
  </div>
</template>

<script>
import { postLogout } from 'Api/modules'
import { clearCookie } from 'Utils/auth'
import { sendMessageToApp } from 'Utils/message'
import SecureLogin from './SecureLogin'
import { mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    SecureLogin,
  },
  data() {
    return {
      isAuthorized: false,
    }
  },
  computed: {
    ...mapGetters(['fcmToken']),
  },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'CLEAR_ACCOUNT_INFO', 'CLEAR_FCM_TOKEN']),
    async secureLogin() {
      try {
        const res = await this.$refs.secLoginForm.retire()
        if (res) {
          this.isAuthorized = true
          await new Promise(resolve => setTimeout(resolve, 10000))
          await postLogout({ fcmToken: this.fcmToken })
          sendMessageToApp('clearKeepLogin')
          this.SET_IS_LOGIN(false)
          this.CLEAR_ACCOUNT_INFO()
          clearCookie()
          this.CLEAR_FCM_TOKEN()
          this.$router.push('/')
        }
      } catch (e) {
        console.error(e)
      }
    },
    async rollbackRelease() {
      try {
        const res = await this.$refs.secLoginForm.rollbackRelease()
        if (res) {
          this.isAuthorized = true
          this.$toasted.show('회원탈퇴 복구가 완료되었습니다.')
          this.$router.push('/')
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
  created() {
    this.isKakao = this.account.joinType === 'KAKAO' ? true : false
    this.isNaver = this.account.joinType === 'NAVER' ? true : false
  },
}
</script>

<style></style>
